import React from "react";
import { useLocation } from 'react-router-dom';
import { Watermark } from 'watermark-js-plus'
import { XSSRecoverText } from "../util/xssHelper";
import Storage from "../common/lib/storage";

const useDavinciWatermark = (initialText) => {
  const location = useLocation();
  const [watermarkText, setWatermarkText] = React.useState(initialText);
  const [watermarkInstance, setWatermarkInstance] = React.useState(null);

  const formatDateTime = (date) => {
    const pad = (num) => num.toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const createOrUpdateWatermark = (content) => {
    const watermark = new Watermark({
      content: content,
      contentType: 'multi-line-text',
      textBaseline: 'bottom',
      width: 300,
      height: 200,
      textRowMaxWidth: 800,
      lineHeight: 15,
      rotate: 30,
      globalAlpha: 0.17,
      fontSize: '14px',
      monitorProtection: true
    });

    watermark.create();
    setWatermarkInstance(watermark);
  };

  React.useEffect(() => {
    if (watermarkInstance) {
      watermarkInstance.destroy();
      createOrUpdateWatermark(watermarkText);
    } else {
      createOrUpdateWatermark(watermarkText);
    }
  }, [watermarkText]);

  const updateWatermark = (userName) => {
    let newText = `${userName ?? ""} ${formatDateTime(new Date())}`;
    setWatermarkText(newText);
  };

  React.useEffect(() => {
    let userName = `${XSSRecoverText(Storage.getUserName()) ?? ""}`;
    updateWatermark(userName);
  }, [location.pathname]);

  return {
    updateWatermark,
  };
};

export default useDavinciWatermark;
